.App {
  text-align: center;
}

.orbit-ring, .orbit-ring-reverse {
  height: 40vmin;
  pointer-events: none;
}

.orbits {
  display: grid;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.orbits > img {
  grid-column-start: 1;
  grid-row-start: 1;
}

#ring0 {
  width: 20vw;
  height: 20vh;
}

#ring1 {
  width: 30vw;
  height: 30vh;
}

#ring2 {
  width: 40vw;
  height: 40vh;
}

#ring3 {
  width: 50vw;
  height: 50vh;
}


@media (prefers-reduced-motion: no-preference) {
  .orbit-ring {
    animation: orbit-ring-spin infinite 100s linear;
  }
  .orbit-ring-reverse {
    animation: orbit-ring-spin-rev infinite 100s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes orbit-ring-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes orbit-ring-spin-rev {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
